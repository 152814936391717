<template>
  <div>
    <b-card class="bg-transparent mb-0">
      <h1>Autorizações LinKapital</h1>
      <h4>Autorização para Consulta ao Sistema de Informações de Crédito</h4>
    </b-card>
    <b-card class="p-3 terms-fs">
      <p>Autorizo a Linkapital Tecnologia e Serviços Financeiros Ltda, CNPJ 37.380.728/0001-04, (“LinKapital”) e a
        Fidúcia Sociedade de Crédito ao Microempreendedor e à Empresa de Pequeno Porte Ltda, CNPJ 04.307.598/0001-17,
        (“Fidúcia SCMEPP”), em caráter irrevogável e irretratável, a (i) consultarem, nos termos da Resolução CMN nº
        4.571, de 26 de maio de 2017, os débitos e responsabilidades decorrentes de operações com características de
        crédito e as informações e os registros de medidas judiciais que, em meu nome e no da empresa {{
    $store.state.auth.userData.empresa.razao_social
}}, CNPJ {{ $store.state.auth.userData.empresa.cnpj }}, constem
        ou venham a constar do Sistema de Informações de Créditos (SCR), gerido pelo Banco Central do Brasil (Bacen), ou
        dos sistemas que venham a complementá-lo ou a substituí-lo, assim como a consultar demais organizações
        centralizadoras de cadastros e informações privadas ou governamentais (SERASA, SCPC, Bureau de Cadastros
        Positivos e outros) sobre eventuais débitos; e (ii) prestar aos citados órgãos as informações dos dados
        cadastrais e dados relativos às operações financeiras que venham a ser celebradas, tudo de conformidade com a
        legislação vigente.</p>
      <p>Outrossim, nos termos do artigo 1º, parágrafo 3º, inciso V da Lei Complementar nº 105 de 10 de janeiro de 2001,
        autorizo a LinKapital a revelar as informações constantes, ou que venham a constar, em meu nome e/ou em nome da
        empresa supra mencionada, no SCR e nas demais organizações centralizadoras de cadastros e informações acima
        indicadas, a possíveis parceiros de negócio, com finalidade e uso dessas informações exclusivamente para a
        avaliação de solicitações de crédito.</p>
      <p>Estou ciente de que:</p>
      <ol>
        <li>o SCR tem por finalidades prover informações ao Banco Central do Brasil, para fins de monitoramento do
          crédito no sistema financeiro e para o exercício de suas atividades de fiscalização e propiciar o intercâmbio
          de informações entre instituições financeiras, conforme definido no § 1º do art. 1º da Lei Complementar nº
          105, de 10 de janeiro de 2001, sobre o montante de responsabilidades de clientes em operações de crédito;</li>
        <li>o SCR é alimentado pelas instituições integrantes do sistema financeiro nacional, mediante coleta de
          informações sobre operações de adiantamentos, empréstimos, financiamentos, dentre outras, , bem como valores
          referentes à prestação de fianças e avais;</li>
        <li>poderei ter acesso gratuito aos dados constantes no SCR por meio do sistema Registrato do Banco Central do
          Brasil (www.bcb.gov.br);</li>
        <li>as informações remetidas para fins de registro no SCR são de exclusiva responsabilidade da instituição
          integrante do sistema financeiro nacional, inclusive no que tange às inclusões, correções, exclusões, registro
          de medidas judiciais e manifestações de discordância quanto às tais informações constantes no sistema, sendo
          certo que somente a instituição responsável pela inclusão poderá alterá-la ou excluí-la;</li>
        <li>pedidos de correções, exclusões e manifestações de discordância quanto às informações constantes do SCR
          deverão ser dirigidas primeiramente à instituição responsável pela remessa das informações. Em caso de não
          entendimento entre as partes, poderá ser registrada reclamação na Central de Atendimento ao Público do Banco
          Central, ou por meio de medida judicial cabível, em face da instituição responsável pelo lançamento de tais
          informações;</li>
        <li>a consulta sobre qualquer informação ao SCR depende de prévia autorização, que é concedida por meio deste
          documento;</li>
        <li>pelo presente, em observância às disposições da Lei nº 13.709, de 14 de agosto de 2018 (“Lei Geral de
          Proteção de Dados”), autorizo o uso de minhas informações pessoais e informações da empresa supra mencionada
          para o fim exclusivo de avaliação das operações de crédito a serem firmadas com a LinKapital ou com seus
          parceiros de negócio.</li>
      </ol>

      <p>Declaro, por fim, ser representante devidamente autorizado da empresa supra mencionada, possuindo poderes para,
        em nome dela, conceder todas as autorizações indicadas no presente documento, estando ciente de que a falsidade
        ideológica na prestação destas informações e autorizações me sujeitará às penalidades previstas em lei. Por meio
        deste documento, responsabilizo-me pelas declarações acima, isentando a LinKapital e seus parceiros de negócio
        de qualquer responsabilidade.</p>

      <p>São Paulo, {{ currentDate }}.</p>

      <p>[Nome do Representante da Empresa]</p>
      <p>[CPF do Representante da Empresa]</p>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BImg, BButton, BLink, BIcon } from 'bootstrap-vue'
export default {
  name: 'ScrAuthorizationTerms',
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BButton,
    BLink,
    BIcon
  },
  data() {
    return {
      currentDate: new Date().toLocaleDateString('pt-BR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    }
  }
}
</script>

<style lang="scss">
.card-offert {
  min-height: 15rem !important;
}

.terms-fs {
  font-size: 14px;
  line-height: 21px;
}
</style>